import { Link } from 'gatsby';
import * as React from 'react';

const NotFoundPage: React.FC = () => (
  <>
    <h1>404: Страница не найдена.</h1>
    <p>
      <Link to="/">На главную.</Link>
    </p>
  </>
);

export default NotFoundPage;
